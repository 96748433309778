<template>
  <div class="container">
    <div class="top">
      <div class="item">
        <span class="left">
          <i class="icon icon-public-peoples icon-7" />
          {{ inviteeCount }} 人
        </span>
        <span class="right"></span>
      </div>
    </div>
    <Sticky>
      <div class="item header">
        <span class="left">好友</span>
        <span class="right">
          <span class="info">首份问卷</span>
          <span class="info">邀请奖励</span>
          <span class="info">发放状态</span>
        </span>
      </div>
      
    </Sticky>
    <ul>
      <li class="item" v-for="(item, index) in inviteeList" :key="index" >
        <span class="left">{{ item.nickName }}</span>
        <span class="right">
          <div class="info">{{ item.finishFirstQuestionnaire | finishFirstQuestionnaireFilter }}</div>
          <div class="info">{{ item.firstAnswerAwardScore || 0 }}</div>
          <div class="info">{{ item.firstAnswerAwardDeliverStatus | firstAnswerAwardDeliverStatusFilter }}</div>
        </span>
      </li>
      
    </ul>
  </div>
</template>

<script>
import Sticky from 'vant/lib/sticky'
import 'vant/lib/sticky/style';
import { getInvitationInfo } from '../api/index'

export default {
  name: "invitationInfo",
  components: {
    Sticky
  },
  filters: {
    finishFirstQuestionnaireFilter (status) {
      const map = {
        Y: '完成',
        N: '未完成'
      }
      return map[status] || '暂无信息'
    },
    firstAnswerAwardDeliverStatusFilter (status) {
      const map = {
        S: '已发放'
      }
      return map[status] || '暂无信息'
    }
  },
  data() {
    return {
      inviteeCount: 0, // 被邀请人总数
      answeredInviteeCount: 0, // 答题的被邀请人总数
      invitationAwardAmount: 0, // 邀请奖励金额
      inviteeList: [] // 
    }
  },
  created() {
    this.doGetInvitationInfo()
  },
  methods: {
    // 查询邀请信息
    async doGetInvitationInfo () {
      let params = { }
      let res = {}
      try {
        res = await getInvitationInfo(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const data = res.result
          this.inviteeCount = data.inviteeCount
          this.answeredInviteeCount = data.answeredInviteeCount
          this.invitationAwardScore = data.invitationAwardScore
          this.invitationAwardAmount = data.invitationAwardAmount
          this.inviteeList = data.inviteeList
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
  }

}
</script>

<style lang="less" scoped>
.container{
  min-height: 100vh;
  .top{
    .item{
      padding: 14px;
    }
    margin-bottom: 10px;
  }
  .header.header{
    font-weight: bold;
    padding: 14px;
  }
  .item{
    display: flex;
    background: white;
    justify-content: space-between;
    padding: 10px 14px;
    border-bottom: 1px solid #ededed;
    // min-height: 40vh;
    .left{
      
    }
    .right{
      flex-shrink: 0;
      .info{
        display: inline-block;
        min-width: 56px;
        margin-right: 10px;
        text-align: center;
        &:nth-last-child(1){
          margin-right: 0;
        }
      }
    }
  }
  li.item{
    color: #666;
  }
}
</style>